import { CellContext, HeaderContext } from "@tanstack/react-table";
import React, { useEffect } from "react";

import { historicalChartMetricAtom, newVsReturningAtom } from "@/atoms/store";
import { useAtom } from "jotai";
import { availableHistoricalChartMetrics } from "@lib/hooks/constants";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import { METRICS_LABELS } from "@/constants/constants";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";

function HistoricalChartHeaderSelect() {
  const [historicalChartMetric, setHistoricalChartMetric] = useAtom(
    historicalChartMetricAtom
  );
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const historicalChartMetrics =
    newVsReturning === "default"
      ? availableHistoricalChartMetrics.filter((el) => el !== "cac")
      : availableHistoricalChartMetrics;
  useEffect(() => {
    if (newVsReturning === "default" && historicalChartMetric === "cac") {
      setHistoricalChartMetric("spend");
    }
  }, [
    historicalChartMetric,
    historicalChartMetrics,
    newVsReturning,
    setHistoricalChartMetric,
  ]);
  return (
    <Select
      defaultValue="spend"
      value={historicalChartMetric}
      onValueChange={(value) =>
        setHistoricalChartMetric(value as typeof historicalChartMetric)
      }
    >
      <Tooltip>
        <TooltipTrigger>
          <SelectTrigger className="w-full focus:ring-0">
            Historical Chart (<SelectValue placeholder="Select a metric" />)
          </SelectTrigger>
        </TooltipTrigger>
        <TooltipContent className="max-w-sm">
          Chart reflecting the development of a chosen metric. Useful for
          identifying trends.
        </TooltipContent>
      </Tooltip>
      <SelectContent>
        {historicalChartMetrics.map((el) => (
          <SelectItem key={el} value={el}>
            {METRICS_LABELS[el]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

export default HistoricalChartHeaderSelect;
