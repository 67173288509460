import RateDisplay from "@components/Cards/RateDisplay";
import useFormat from "@lib/hooks/use-format";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import { newVsReturningAtom } from "atoms";
import { useAtom } from "jotai";
import React from "react";
import { getCorrelation, getTotalValueOfAllRows } from "./calculations";
type Props<T> = {
  compared?: boolean;
} & (
  | {
      info: CellContext<T, number | undefined>;
      calculateTotals?: false;
    }
  | {
      info: HeaderContext<T, number | undefined>;
      calculateTotals: true;
    }
);

export type CurrencyCalculatorType = (
  value: number,
  numDigits?: number
) => string;

const getDisplayString = (
  value: number,
  formatNumber: CurrencyCalculatorType
) => {
  return `${formatNumber(value * 100, 2)}%`;
};

function NewVisitorRateTableDisplay<T extends Record<string, any>>({
  info,
  compared,
  calculateTotals,
}: Props<T>) {
  const { formatNumber } = useFormat();
  const [newVsReturning] = useAtom(newVsReturningAtom);
  let displayNumbers = false;
  let comparedValue = 0;
  let value = 0;
  if (newVsReturning !== "new" && newVsReturning !== "all") {
    return "-";
  }
  if (calculateTotals) {
    const allNewPageviewCount = info.column
      .getFacetedRowModel()
      .rows.map((row) => {
        return {
          value:
            row.original?.[newVsReturning === "new" ? "pageview" : "nvr_upv"],
          compared:
            row.original?.compared?.[
              newVsReturning === "new" ? "pageview" : "nvr_upv"
            ],
        };
      });
    const allPageviewValues = info.column
      .getFacetedRowModel()
      .rows.map((row) => {
        return {
          value:
            row.original?.[newVsReturning === "new" ? "nvr_upv" : "pageview"],
          compared:
            row.original?.compared?.[
              newVsReturning === "new" ? "nvr_upv" : "pageview"
            ],
        };
      });
    const newPageviewTotals = getTotalValueOfAllRows({
      values: allNewPageviewCount,
    });
    const pageviewTotals = getTotalValueOfAllRows({
      values: allPageviewValues,
    });
    if (
      newPageviewTotals.value >= 1 ||
      (newPageviewTotals.compared ?? 0) >= 1
    ) {
      displayNumbers = true;
    }

    value =
      pageviewTotals.value && newPageviewTotals.value
        ? newPageviewTotals.value / pageviewTotals.value
        : 0;
    comparedValue =
      pageviewTotals.compared && newPageviewTotals.compared
        ? newPageviewTotals.compared / pageviewTotals.compared
        : 0;
  } else {
    value = info.getValue() ?? 0;
    if (compared && info.row.original?.compared) {
      comparedValue = info.row.original?.compared[info.column?.id];
    }

    if (value || comparedValue) {
      displayNumbers = true;
    }
  }

  if (compared) {
    const rate = getCorrelation(value, comparedValue, 4);
    return (
      <div className="flex items-center w-full">
        <div className="flex-1">
          <p>{displayNumbers ? getDisplayString(value, formatNumber) : "-"}</p>
          {displayNumbers ? (
            <p className="text-[11px] leading-tight opacity-70">
              vs {getDisplayString(comparedValue, formatNumber)}
            </p>
          ) : null}
        </div>
        {displayNumbers ? (
          <RateDisplay rate={rate} isIncreasing={rate > 0} size="small" />
        ) : null}
      </div>
    );
  }
  return displayNumbers ? getDisplayString(value, formatNumber) : "-";
}

export default NewVisitorRateTableDisplay;
