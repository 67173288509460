import RateDisplay from "@components/Cards/RateDisplay";
import useCurrency from "@lib/hooks/use-currency";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import React from "react";
import { getCorrelation, getTotalValueOfAllRows } from "./calculations";
import { Line } from "recharts";
import { Area, ComposedChart } from "recharts";
import { ResponsiveContainer } from "recharts";
import dayjs from "dayjs";
import { HistoricalData } from "@api/types/backendTypes";
import { historicalChartMetricAtom } from "@/atoms/store";
import { useAtom } from "jotai";

type Props<T> = {
  compared?: boolean;
  info: CellContext<T, number | undefined>;
};

function HistoricalChartDisplay<T extends Record<string, any>>({
  info,
  compared,
}: Props<T>) {
  const [historicalChartMetric] = useAtom(historicalChartMetricAtom);
  const comparedValue = info.row.original?.compared?.historical as
    | HistoricalData[]
    | undefined;
  const value = info.getValue() as HistoricalData[] | undefined;
  const newValues: {
    date: string;
    value: number;
    compared_value?: number;
  }[] = [];
  let index = 0;

  if (comparedValue && value) {
    const dates = value?.map((el) => dayjs(el.date).unix());
    const maxDateFromDaily = Math.max(...dates);
    const minDateFromDaily = Math.min(...dates);
    const timerange =
      dayjs(maxDateFromDaily * 1000).diff(
        dayjs(minDateFromDaily * 1000),
        "day"
      ) + 1; // we do + 1 because start is at 00:00 and end at 23:59 which is 1 day

    for (const values of value ?? []) {
      const comparedDate = dayjs(values.date)
        .subtract(timerange, "day")
        .format("YYYY-MM-DD");
      const comparedDateValue = comparedValue?.find(
        (el) => el.date === comparedDate
      );
      newValues[index] = {
        date: values.date,
        value: values[historicalChartMetric] ?? 0,
      };
      if (comparedDateValue) {
        newValues[index].compared_value =
          comparedDateValue[historicalChartMetric] ?? 0;
      }
      index++;
    }
  } else {
    for (const values of value ?? []) {
      newValues[index] = {
        date: values.date,
        value: values[historicalChartMetric] ?? 0,
      };
      index++;
    }
  }
  return (
    <div className="h-12 w-full max-w-xs">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={newValues}>
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#6254FF" stopOpacity={0.6} />
              <stop offset="80%" stopColor="#6254FF" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            stroke="#6254FF"
            fillOpacity={compared ? 0 : 1}
            fill="url(#colorValue)"
            dot={false}
            connectNulls
          />
          {compared ? (
            <Line
              type="monotone"
              dataKey="compared_value"
              strokeDasharray={"5 5"}
              stroke="#6254FF"
              strokeWidth={2}
              dot={false}
              connectNulls
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default HistoricalChartDisplay;
