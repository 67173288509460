import RateDisplay from "@components/Cards/RateDisplay";
import useFormat from "@lib/hooks/use-format";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import { newVsReturningAtom } from "atoms";
import { useAtom } from "jotai";
import React from "react";
import { getCorrelation, getTotalValueOfAllRows } from "./calculations";
type Props<T> = {
  compared?: boolean;
} & (
  | {
      info: CellContext<T, number | undefined>;
      calculateTotals?: false;
    }
  | {
      info: HeaderContext<T, number | undefined>;
      calculateTotals: true;
    }
);

export type CurrencyCalculatorType = (
  value: number,
  numDigits?: number
) => string;

const getDisplayString = (
  value: number,
  formatNumber: CurrencyCalculatorType
) => {
  return `${formatNumber(value * 100, 2)}%`;
};

function NewCustomerRateTableDisplay<T extends Record<string, any>>({
  info,
  compared,
  calculateTotals,
}: Props<T>) {
  const { formatNumber } = useFormat();

  const [newVsReturning] = useAtom(newVsReturningAtom);
  let displayNumbers = false;
  let comparedValue = 0;
  let value = 0;
  if (newVsReturning !== "new" && newVsReturning !== "all") {
    return "-";
  }
  if (calculateTotals) {
    const allNewOrdersCount = info.column
      .getFacetedRowModel()
      .rows.map((row) => {
        return {
          value:
            row.original?.[
              newVsReturning === "new" ? "purchaseCount" : "nvr_purchaseCount"
            ],
          compared:
            row.original?.compared?.[
              newVsReturning === "new" ? "purchaseCount" : "nvr_purchaseCount"
            ],
        };
      });
    const allOrderValues = info.column.getFacetedRowModel().rows.map((row) => {
      return {
        value:
          row.original?.[
            newVsReturning === "new" ? "nvr_purchaseCount" : "purchaseCount"
          ],
        compared:
          row.original?.compared?.[
            newVsReturning === "new" ? "nvr_purchaseCount" : "purchaseCount"
          ],
      };
    });
    const newOrdersTotals = getTotalValueOfAllRows({
      values: allNewOrdersCount,
    });
    const orderTotals = getTotalValueOfAllRows({
      values: allOrderValues,
    });
    if (newOrdersTotals.value >= 1 || (newOrdersTotals.compared ?? 0) >= 1) {
      displayNumbers = true;
    }

    value =
      orderTotals.value && newOrdersTotals.value
        ? newOrdersTotals.value / orderTotals.value
        : 0;
    comparedValue =
      orderTotals.compared && newOrdersTotals.compared
        ? newOrdersTotals.compared / orderTotals.compared
        : 0;
  } else {
    value = info.getValue() ?? 0;
    if (compared && info.row.original?.compared) {
      comparedValue = info.row.original?.compared[info.column?.id];
    }

    if (
      (info.row.original?.purchaseCount &&
        info.row.original?.purchaseCount >= 1) ||
      (info.row.original?.compared?.purchaseCount &&
        info.row.original?.compared?.purchaseCount >= 1)
    ) {
      displayNumbers = true;
    }
  }

  if (compared) {
    const rate = getCorrelation(value, comparedValue, 4);
    return (
      <div className="flex items-center w-full">
        <div className="flex-1">
          <p>{displayNumbers ? getDisplayString(value, formatNumber) : "-"}</p>
          {displayNumbers ? (
            <p className="text-[11px] leading-tight opacity-70">
              vs {getDisplayString(comparedValue, formatNumber)}
            </p>
          ) : null}
        </div>
        {displayNumbers ? (
          <RateDisplay rate={rate} isIncreasing={rate > 0} size="small" />
        ) : null}
      </div>
    );
  }
  return displayNumbers ? getDisplayString(value, formatNumber) : "-";
}

export default NewCustomerRateTableDisplay;
